import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  TextField,
  MenuItem,
  Select,
  Button,
  Typography,
  Grid,
  Link
} from "@material-ui/core"
import AppFooter from "../AppFooter"
import AppForm from "../AppForm"
import isEmail from "validator/lib/isEmail"
import isTel from "validator/lib/isMobilePhone"
import { navigate } from "gatsby"
import FormFeedback from "../formFields/FormFeedback"
import { Auth } from "aws-amplify"
import addToMailchimp from "gatsby-plugin-mailchimp"
//@ts-ignore
import telCodes from "./telcodes.json"

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(6)
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
      //fontWeight: "bold"
    },
    reVerifyButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[100]
    },
    feedback: {
      marginTop: theme.spacing(2)
    },
    dropDown: {
      marginTop: 18
    },
    pointerClick: {
      cursor: "pointer"
    }
  })

interface SignupProps extends WithStyles<typeof styles> {}

const formVal = {
  value: "",
  isDirty: false,
  errorMessage: ""
}

const Signup = (props: SignupProps) => {
  const { classes } = props
  //const [telCode, setTelCode] = React.useState("+1")
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [formFields, setFormFields] = React.useState({
    email: { ...formVal },
    //phone_number: { ...formVal },
    password: { ...formVal },
    verificationCode: { ...formVal }
  })
  const [feedBackError, setFeedBackError] = React.useState("")
  const [feedBackSuccess, setFeedBackSuccess] = React.useState("")
  const [awaitingVerification, setAwaitingVerification] = React.useState(false)
  const [formDisabled, setFormDisabled] = React.useState(false)

  React.useEffect(() => {
    let formIsValid = true
    for (let key in formFields) {
      if (key === "verificationCode" && !awaitingVerification) {
        continue
      }
      if (!!formFields[key].errorMessage || !formFields[key].value) {
        formIsValid = false
        break
      }
    }
    setSubmitDisabled(!formIsValid)
  }, [formFields])

  const formHandler = e => {
    const newForState = {
      ...formFields,
      [e.target.name]: {
        value: e.target.value.trim(),
        isDirty: true,
        errorMessage: validate(e.target.name, e.target.value)
      }
    }
    setFormFields(newForState)
  }

  const validate = (name: string, value: string) => {
    if (["email", "password"].indexOf(name) >= 0) {
      if (value.length == 0) return "field required"
      if (name == "email") {
        return !isEmail(value.trim()) ? "Invalid email" : null
      }
    }
    if (name === "verificationCode" && awaitingVerification) {
      if (value.length == 0) return "field required"
    }
    return ""
  }

  const submit = async e => {
    e.preventDefault()
    if (!awaitingVerification) {
      try {
        const result = await Auth.signUp({
          username: formFields.email.value,
          password: formFields.password.value,
          attributes: {
            email: formFields.email.value /*, phone_number: `${telCode}${formFields.phone_number.value}` */
          }
        })
        setAwaitingVerification(true)
        setFormDisabled(true)
        setSubmitDisabled(true)
        setFeedBackError("")
        const data = await addToMailchimp(formFields.email.value)
        setFeedBackSuccess(`Please check ${result.codeDeliveryDetails.Destination} for the verification code.`)
      } catch (err) {
        setFeedBackError(err.message)
        setFeedBackSuccess("")
      }
    } else {
      try {
        await Auth.confirmSignUp(formFields.email.value, formFields.verificationCode.value)
        setFeedBackError("")
        setFeedBackSuccess("Sign up successful. Thank you!")
        const sgn = await Auth.signIn(formFields.email.value, formFields.password.value)
        console.log(sgn)
        setTimeout(() => {
          navigate("/course")
        }, 300)
      } catch (err) {
        setFeedBackSuccess("")
        setFeedBackError(err.message)
      }
    }
  }

  const GetNewVerificationCode = async () => {
    try {
      const result = await Auth.resendSignUp(formFields.email.value)
      console.log(result)
      setFeedBackError("")
      setFeedBackSuccess("verification code resent")
    } catch (err) {
      setFeedBackError(err)
      setFeedBackSuccess("")
    }
  }

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            <Link onClick={() => navigate("/sign-in")} underline="always" className={classes.pointerClick}>
              Already have an account?
            </Link>
          </Typography>
        </React.Fragment>
        <form className={classes.form} noValidate>
          <TextField
            autoComplete="email"
            disabled={formDisabled}
            placeholder="Email"
            label={formFields.email.errorMessage || "Email"}
            error={!!formFields.email.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            margin="normal"
            name="email"
            required
            fullWidth
          />
          {!awaitingVerification && (
            <>
              <TextField
                placeholder="Password"
                label={formFields.password.errorMessage || "Password"}
                error={!!formFields.password.errorMessage}
                onChange={formHandler}
                onBlur={formHandler}
                fullWidth
                disabled={formDisabled}
                required
                name="password"
                autoComplete="current-password"
                type="password"
                margin="normal"
              />
              {/* <Grid container alignItems="center" spacing={2}>
                <Grid item xs={2} sm={2}>
                  <Select
                    value={telCode}
                    onChange={(e: any) => setTelCode(e.target.value)}
                    fullWidth
                    className={classes.dropDown}
                    required
                    name="phone_code"
                    disabled={formDisabled}
                    inputProps={{ name: "tel_code", label: "tel_code" }}
                  >
                    {telCodes.map(code => (
                      <MenuItem key={`+${code}`} value={`+${code}`}>{`+${code}`}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={10} sm={10}>
                  <TextField
                    placeholder="Phone Number"
                    label={formFields.phone_number.errorMessage || "Phone Number"}
                    error={!!formFields.phone_number.errorMessage}
                    onChange={formHandler}
                    onBlur={formHandler}
                    autoComplete="phone_number"
                    fullWidth
                    name="phone_number"
                    type="text"
                    required
                    disabled={formDisabled}
                  />
                </Grid>
              </Grid> */}
            </>
          )}
          {awaitingVerification && (
            <TextField
              placeholder="Verification Code"
              label={formFields.verificationCode.errorMessage || "Verification Code"}
              error={!!formFields.verificationCode.errorMessage}
              onChange={formHandler}
              onBlur={formHandler}
              fullWidth
              name="verificationCode"
              type="text"
              required
            />
          )}

          <Button type="submit" className={classes.button} disabled={submitDisabled} fullWidth onClick={submit}>
            {awaitingVerification ? "submit code" : "Sign Up"}
          </Button>
          <Typography variant="body2" align="center">
            By signing up you agree to the{" "}
            <Link onClick={() => navigate("/terms-of-use")} className={classes.pointerClick}>
              terms
            </Link>
          </Typography>
        </form>
        <FormFeedback error={!!feedBackError} className={classes.feedback} success={!!feedBackSuccess}>
          {feedBackError || feedBackSuccess}
        </FormFeedback>
        <Typography variant="body2" align="center">
            *NB Email address is case sensitive. When logging onto a different device, please ensure you enter the exact same e-mail.
          </Typography>
        {awaitingVerification && (
          <Button className={classes.reVerifyButton} fullWidth onClick={GetNewVerificationCode}>
            resend verification code
          </Button>
        )}
      </AppForm>
    </React.Fragment>
  )
}
export default withStyles(styles)(Signup)
